.btn-ctr {
    position: relative;
    display: block;
    text-align: center;
    border: none;
    background: none;
    cursor: pointer;
    transition: color .5s ease-in-out;
    letter-spacing: 1px;
    color: var(--accent);
    /* width: 100%;
    height: 100%; */
}

.btn-ctr:hover, 
.btn-ctr:focus {
    color: var(--darkGray);
    outline: none;
}
.btn-ctr:hover .btn-bg,
.btn-ctr:focus .btn-bg {
    width: 120%;
}


.btn-inner {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.btn-bg {
    position: absolute;
    left: -10%;
    background-color: var(--accent);
    width: 0;
    height: 100%;
    z-index: 3;
    transform: skewX(-20deg);
    transition: all .5s ease-in-out;
}

.btn-base {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    border: 1px solid var(--accent);
}

.btn-text {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 4;
    transition: all .1s;
}
