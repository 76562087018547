.popup {
    position: fixed;
    left: 0; 
    right: 0; 
    bottom: 0; 
    top: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 580px;
    width: 60vw;
    max-width: 900px;
    height: fit-content;
    max-height: 100vh;
    /* height: 95vh; */
    background-color: var(--darkGray);
    border: 2px solid var(--accent);
    border-radius: 4px;
    box-shadow: 1px 1px 10px 3px black;
    z-index: 110;
    overflow-y: scroll;

    visibility: hidden;
    opacity: 0;

    transition: opacity .5s, visibility .5s, transform .5s;
}
.popup::-webkit-scrollbar {
    display: none;
}
.popup {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}
.popup-active {
    opacity: 1;
    visibility: visible;
}

.popup-header {
    font-size: 1.4rem;
    padding: 40px;
    text-align: center;
}

.popup-body {
    display: flex;
    flex-wrap: wrap;
}

.popup-item {
    width: 50%;
    padding: 10px 40px;
}

.popup-bottom {
    padding: 20px
}

.popup-links {
    display: flex;
    flex-wrap: wrap;
    /* flex-basis: 50%; */
    justify-content: space-evenly;
    margin-bottom: 30px;
}

.popup-links button {
    margin-top: 20px;
}
@media only screen and (max-width: 600px) {
    .popup {
        min-width: 100vw;
        width: 100vw;
    }

    .popup-item {
        width: 100%;
    }
}