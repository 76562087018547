@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,500;1,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,500;1,400;1,500&display=swap);
/*  ==============
    General Styles
    ============== */

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}

a {
	text-decoration: none;
	color: inherit;
}

#page-background {
	width: 100vw;
	/* position: fixed;
    top: -150px; */
}

#video {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 0;
}
@media (min-aspect-ratio: 16/9) {
	.showcase {
		background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
			url(/static/media/LandingBackground-desktop.36bce882.jpg);
		background-size: cover;
		background-position: 50%;
	}
}
@media (max-aspect-ratio: 16/9) {
	.showcase {
		background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
			url(/static/media/LandingBackground-mobile.34202236.jpg);
		background-size: cover;
		background-position: 50%;
	}
}

.showcase {
	color: white;
	overflow-y: hidden;
}

/*  =========
    Keyframes
    ========= */

@-webkit-keyframes bar-load {
	0% {
		width: 0;
	}

	100% {
		width: 200%;
	}
}

@keyframes bar-load {
	0% {
		width: 0;
	}

	100% {
		width: 200%;
	}
}

@-webkit-keyframes title-load {
	0% {
		-webkit-transform: translateY(100px);
		        transform: translateY(100px);
	}

	90% {
		-webkit-transform: translateY(-4px);
		        transform: translateY(-4px);
	}

	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes title-load {
	0% {
		-webkit-transform: translateY(100px);
		        transform: translateY(100px);
	}

	90% {
		-webkit-transform: translateY(-4px);
		        transform: translateY(-4px);
	}

	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes sites-load {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes sites-load {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes scale-down {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
	}
}

@keyframes scale-down {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
	}
}

@-webkit-keyframes overlay {
	0% {
		opacity: 0;
		visibility: visible;
	}
	100% {
		opacity: 0.4;
	}
}

@keyframes overlay {
	0% {
		opacity: 0;
		visibility: visible;
	}
	100% {
		opacity: 0.4;
	}
}

@-webkit-keyframes load {
	0% {
		width: 0%;
		opacity: 1;
	}
	50% {
		width: 100%;
		opacity: 1;
	}
	100% {
		width: 100%;
		opacity: 1;
	}
}

@keyframes load {
	0% {
		width: 0%;
		opacity: 1;
	}
	50% {
		width: 100%;
		opacity: 1;
	}
	100% {
		width: 100%;
		opacity: 1;
	}
}

@-webkit-keyframes left-arrow {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes left-arrow {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.full-screen {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	min-height: 100vh;
}

/*  =============
    Header Styles
    ============= */

.showcase header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 70%;
	display: block;
	text-align: center;
	overflow: hidden;
}

.title {
	margin-top: 40px;
	padding: 5px;
	-webkit-transform: translateY(100px);
	        transform: translateY(100px);
}

.title-load {
	-webkit-animation: title-load 1.5s 1s;
	        animation: title-load 1.5s 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-transform: translateY(100px);
	        transform: translateY(100px);
}

header hr {
	background-image: linear-gradient(
		to right,
		rgb(7, 205, 255),
		rgb(34, 185, 153)
	);
	border: none;
	height: 2px;
	position: absolute;
	left: -100%;
	right: 0;
	bottom: 0;
	margin: auto;

	-webkit-animation-fill-mode: forwards;

	        animation-fill-mode: forwards;
	width: 0;
}

.hr-load {
	-webkit-animation: bar-load 0.7s ease-in 0.1s;
	        animation: bar-load 0.7s ease-in 0.1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	width: 0;
}

/*  ============
    Links Styles
    ============ */

.sites-container {
	font-weight: 400;
	font-family: 'Proza Libre', sans-serif;
	font-style: italic;
	width: 100%;
	display: flex;
	justify-content: center;
}
.sites {
	font-size: 1.875rem;
	position: absolute;
	top: 35%;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 50px;
}
.left-container,
.right-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.musician {
	cursor: pointer;
	opacity: 0;

	-webkit-animation: sites-load 0.5s ease-in-out 4.5s;

	        animation: sites-load 0.5s ease-in-out 4.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}
.musician:hover .left {
	display: none;
}
.developer {
	opacity: 0;
	cursor: pointer;

	-webkit-animation: sites-load 0.5s ease-in-out 5.5s;

	        animation: sites-load 0.5s ease-in-out 5.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.left-arrow-hover {
	opacity: 1 !important;
	-webkit-transform: translateX(-15px) !important;
	        transform: translateX(-15px) !important;
}
.right-arrow-hover {
	opacity: 1 !important;
	-webkit-transform: translateX(15px) !important;
	        transform: translateX(15px) !important;
}

#middle {
	align-self: center;
	opacity: 0;

	-webkit-animation: sites-load 0.5s ease-in-out 5s;

	        animation: sites-load 0.5s ease-in-out 5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.left-hidden {
	opacity: 0;
	visibility: hidden;

	transition: all 0.5s ease-in-out;
}
.left-visible {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateX(-15px);
	        transform: translateX(-15px);

	transition: all 0.5s ease-in-out;
}
.right-hidden {
	opacity: 0;
	visibility: hidden;

	transition: all 0.5s ease-in-out;
}
.right-visible {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateX(15px);
	        transform: translateX(15px);

	transition: all 0.5s ease-in-out;
}

.construction {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	background: white;
	box-shadow: 1px 1px 8px 1px black;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 100;

	transition: all 0.5s ease-in-out;
}

.construction span {
	margin-top: 2rem;
	font-size: 1.5rem;
}

.close-construction {
	cursor: pointer;
	font-size: 2rem !important;
	color: black;
	position: absolute;
	right: 10px;
	top: 0;
	margin-top: 0 !important;
}

.overlay {
	background-color: rgb(34, 34, 34, 0.5);
	pointer-events: all;

	transition: all 0.5s ease-in-out;
}

.invisible {
	opacity: 0;
	visibility: hidden;
}

@media only screen and (max-width: 1000px) {
	#fenner {
		padding: 0;
		margin: -90px;
	}
	.sites {
		-webkit-transform: translateY(-30%);
		        transform: translateY(-30%);
		display: flex;
		flex-direction: column;
		top: 50%;
		font-size: 1.4rem;
		padding: 0;
	}
	.left-container {
		-webkit-transform: translateX(-25%);
		        transform: translateX(-25%);
		height: 50px;
	}
	.right-container {
		-webkit-transform: translateX(22%);
		        transform: translateX(22%);
		height: 50px;
	}
}

@media only screen and (max-width: 500px) {
	html,
	body {
		max-width: 100%;
		overflow-x: hidden;
	}
	#fenner {
		margin: -50px;
	}
	.sites {
		flex-direction: column;
		top: 50%;
		font-size: 1.2rem;
		padding: 0;
	}
	.left-container {
		-webkit-transform: translateX(-25%);
		        transform: translateX(-25%);
	}
	.right-container {
		-webkit-transform: translateX(14%);
		        transform: translateX(14%);
	}
}

@media only screen and (min-width: 1350px) {
	.sites {
		font-size: 2.25rem;
	}
}

/* .test {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: red;
    border-radius: 50%;
    overflow: hidden;

    transition: all 1s ease-out;
} */

@-webkit-keyframes left{
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(-35%);
                transform: translateX(-35%);
    }
    80% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@keyframes left{
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(-35%);
                transform: translateX(-35%);
    }
    80% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@-webkit-keyframes middle {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    4% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    20% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    40% {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
    60% {
        -webkit-transform: rotate(1080deg);
                transform: rotate(1080deg);
    }
    78% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
}
@keyframes middle {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    4% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    20% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    40% {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
    60% {
        -webkit-transform: rotate(1080deg);
                transform: rotate(1080deg);
    }
    78% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
}
@-webkit-keyframes right{
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(35%);
                transform: translateX(35%);
    }
    80% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@keyframes right{
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(35%);
                transform: translateX(35%);
    }
    80% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@-webkit-keyframes left-to-middle {
    from {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    } to {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}
@keyframes left-to-middle {
    from {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    } to {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}
@-webkit-keyframes middle-to-right {
    from {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    } to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}
@keyframes middle-to-right {
    from {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    } to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}


/*  =============
    Loader Styles
    ============= */
.loader {
    background-color: rgb(22, 22, 22);
    color: white;
    font-size: 20px;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);

    transition: -webkit-transform .5s ease-in-out;

    transition: transform .5s ease-in-out;

    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
}

.show {
    -webkit-animation: left-to-middle .5s ease-in-out;
            animation: left-to-middle .5s ease-in-out;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}
.away {
    -webkit-animation: middle-to-right .5s ease-in-out;
            animation: middle-to-right .5s ease-in-out;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}

.load-container {
    height: 16vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.gif {
    display: flex;
}

#one {
    position: relative;
    left: 100px;
    z-index: 3;

    -webkit-animation: left 2s ease-in-out infinite;

            animation: left 2s ease-in-out infinite;
}
#two {
    z-index: 2;

    -webkit-animation: middle 2s linear infinite;

            animation: middle 2s linear infinite;
}
#three {
    position: relative;
    right: 106px;
    z-index: 1;

    -webkit-animation: right 2s ease-in-out infinite;

            animation: right 2s ease-in-out infinite;
}

@media only screen and (min-width: 1150px) {
    .load-container {
        -webkit-transform: scale(1.5);
                transform: scale(1.5)
    }
}
#audio {
    display: none;
}

.sound-icon {
    z-index: 100;
    cursor: pointer;
    position: fixed;
    right: 20px; 
    top: 20px;
}

@media only screen and (max-width: 1000px) {
    .sound-icon-dev {
        top: 88px;
    }
}

@media only screen and (max-width: 600px) {
    .sound-icon-dev {
        top: 14px;
        right: 72px;
    }
}
.confirm-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to bottom right, rgb(49, 49, 49), rgb(20, 20, 20));
}

.confirm-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    max-width: 380px;
    padding: 24px;
    background: var(--lightGray);
    color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 black;
}

.header h1 {
    margin-bottom: 0;
}

.body {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    letter-spacing: .075em;
    font-weight: 300;
}

.body > span {
    padding: 10px 0;
}
 
#back-link {
    cursor: pointer;
    transition: all .3s linear;
    font-weight: 500;
    padding: 10px 0;
}
#back-link:hover,
#back-link:focus {
    color: var(--accent);
}
 @media only screen and (max-width: 500px) {
     .confirm-card {
         width: 90%;
     }
 }
.logo {
	cursor: pointer;
	padding: 0 20px;
	background: transparent;
	position: fixed;
	top: -25px;
	z-index: 30;
	transition: opacity 0.6s ease-in-out 0.6s;
	/* opacity: 0; */

	/* animation: logo-load 1s ease-in-out 3800ms; */
	/* animation-fill-mode: forwards; */
}
.logo-change {
	opacity: 0;
	transition-delay: 0s;
}
.logo-small {
	cursor: pointer;
	padding: 0 20px;
	background: transparent;
	position: fixed;
	top: 27px;
	left: 51.5px;
	z-index: 29;
	transition: all 1s ease-in-out;

	/* animation: logo-load 2s ease-in-out 3800ms;
    animation-fill-mode: forwards;
    opacity: 0; */
}
.logo-small-change {
	-webkit-transform: translate(-30px) scale(2);
	        transform: translate(-30px) scale(2);
}
.logo-small-change-back {
	-webkit-transform: translate(0) scale(1);
	        transform: translate(0) scale(1);
}

nav {
	position: fixed;
	left: 0px;
	top: 120px;
	padding: 10px;
	z-index: 100;
	display: flex;
	flex-direction: column;
	transition: all 0.5s;
	background-color: var(--darkGray);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.575), 0 8px 16px rgba(0, 0, 0, 0.575);

	text-align: center;
	justify-content: space-between;

	-webkit-animation: nav-load 0.6s ease-in-out 4s;

	        animation: nav-load 0.6s ease-in-out 4s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

nav ul {
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 20px;
}

nav li {
	margin: 10px 0;
	padding: 0 10px;
}

.media-container li {
	margin: 5px 0;
}

.burger {
	display: none;
	cursor: pointer;
	z-index: 13;
	position: fixed;
	right: 0;
	top: 0;
	padding: 20px;
}

.burger div {
	width: 25px;
	height: 3px;
	background-color: rgb(247, 247, 247);
	border: 2px, solid, rgb(247, 247, 247);
	border-radius: 10px;
	z-index: 4;
	margin: 5px;
	transition: all 0.5s ease;
}

/*  ===========
    Icon styles
    =========== */

.icon {
	cursor: pointer;
	margin-top: 20px;
	padding-top: 0px;
	transition: all 0.5s ease-in-out 0.05s;
	-webkit-animation: icon-load 0.5s ease-in-out;
	        animation: icon-load 0.5s ease-in-out;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-transform: scale(0);
	        transform: scale(0);
}
.icon:hover {
	opacity: 0;
}

.nav-button {
	position: relative;
}
.nav-button:hover .icon-text {
	opacity: 1;
	transition: opacity 0.5s ease-in-out 0.05s;
}
.nav-button:hover .icon {
	opacity: 0;
	transition-delay: 0s;
}

.nav-button-text {
	position: absolute;
	top: 25px;
}

#home-icon {
	-webkit-animation-delay: 5.2s;
	        animation-delay: 5.2s;
}

#about-icon {
	-webkit-animation-delay: 5.4s;
	        animation-delay: 5.4s;
}

#projects-icon {
	-webkit-animation-delay: 5.6s;
	        animation-delay: 5.6s;
}

#contact-icon {
	-webkit-animation-delay: 5.8s;
	        animation-delay: 5.8s;
}

#github-icon {
	-webkit-animation: icon-load 0.5s ease-in-out;
	        animation: icon-load 0.5s ease-in-out;
	-webkit-animation-delay: 6s;
	        animation-delay: 6s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-transform: scale(0);
	        transform: scale(0);
}
#linkedin-icon {
	-webkit-animation: icon-load 0.5s ease-in-out;
	        animation: icon-load 0.5s ease-in-out;
	-webkit-animation-delay: 6.2s;
	        animation-delay: 6.2s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-transform: scale(0);
	        transform: scale(0);
}

.icon-text {
	cursor: pointer;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: var(--accent);
	font-style: normal;
	font-size: 13px;
	opacity: 0;
	/* visibility: hidden; */

	transition: opacity 0.5s;
}

@media only screen and (max-width: 1000px) {
	nav {
		right: 0px;
		left: auto;
		top: 0px;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		height: 70px;
		width: 400px;
		transition: none;

		-webkit-animation: nav-tablet-load 0.6s ease-in-out;

		        animation: nav-tablet-load 0.6s ease-in-out;
		-webkit-animation-fill-mode: forwards;
		        animation-fill-mode: forwards;
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}
	nav ul {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		width: 60%;
		margin: 20px;
		padding: 0;
	}
	nav li {
		margin: 0;
		padding: 0;
	}
	.media-container {
		display: flex;
		justify-content: center;
		width: 25%;
	}

	#home-text {
		position: relative;
		top: -26px;
	}
	#projects-text {
		position: relative;
		top: -26px;
	}
	#about-text {
		position: relative;
		top: -26px;
	}
}

@media only screen and (max-width: 600px) {
	.logo {
		display: none;
	}
	.logo-small {
		padding: 0 20px;
		background: transparent;
		position: fixed;
		top: 0;
		left: 0;
		padding: 0px 20px;
		z-index: 29;
		transition: all 1s ease-in-out;
		-webkit-transform: scale(1.4);
		        transform: scale(1.4);

		-webkit-animation: logo-load 2s ease-in-out;

		        animation: logo-load 2s ease-in-out;
	}
	nav {
		width: 100vw;
		top: 69px;
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
		opacity: 0;

		transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;

		transition: transform 1s ease-in-out, opacity 1s ease-in-out;

		transition: transform 1s ease-in-out, opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
		-webkit-animation: none;
		        animation: none;
	}
	.nav-toggle {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 1;
	}
	.burger {
		display: block;
		z-index: 50;
	}
	.burger-toggle .line1 {
		-webkit-transform: rotate(-45deg) translate(-5px, 6px);
		        transform: rotate(-45deg) translate(-5px, 6px);
	}
	.burger-toggle .line2 {
		opacity: 0;
	}
	.burger-toggle .line3 {
		-webkit-transform: rotate(45deg) translate(-5px, -6px);
		        transform: rotate(45deg) translate(-5px, -6px);
	}
	.pseudo-nav {
		position: fixed;
		width: 100vw;
		height: 70px;
		z-index: 28;
		background-color: var(--darkGray);
		transition: background-color 0.6s ease-in-out;
	}
	.nav-gray {
		background-color: var(--darkGray);
	}
}

.resume-button {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background-color: rgb(27, 27, 27);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 8px 1px black;
    z-index: 100;

    -webkit-animation: btn-load .6s ease-in-out .3s;

            animation: btn-load .6s ease-in-out .3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
}
#resume-icon {
    transition: opacity .6s;
    margin-top: 0;
}
.resume-button:hover .icon-text {
    opacity: 1;
}
.resume-button:hover .icon {
    opacity: 0;
}
#resume-text {
    left: 6px;
}

@media only screen and (max-width: 600px) {
    .resume-button {
        bottom: 15px;
        right: 15px;
    }
}

section#home {
    height: 100vh;
    position: relative;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: 18%;
    top: 40%;
    line-height: 3.5rem;
    z-index: 0;
}

#home h1 {
    font-size: 60px;
    position: relative;
    top: 0;
    color: white;
}

#home h3 {
    font-size: 28px;
    font-weight: 400;
}

#jacob {
    color: var(--accent) !important;
    margin-left: 20px;
}

.name-container {
    display: flex;
}

.view-work-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    right: 25%;
    top: 50%;
    border: 2px solid white;
    padding: 5px;
    padding-left: 13px;

    transition: color .3s ease-out, border .3s ease-out, opacity .75s ease-in-out;
}
.view-work-button:hover {
    color: var(--accent);
    border: 2px solid var(--accent);
}
.view-work-button:hover #down-arrow {
    fill: var(--accent);
}

#down-arrow {
    fill: white;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    -webkit-animation: down-arrow 2s infinite;
            animation: down-arrow 2s infinite;

    transition: fill .3s ease-out;
}

@media only screen and (min-width: 1358px) {
    #home h1 {
        font-size: 4.5rem;
    }
    #home h3 {
        font-size: 2rem;
    }
    .view-work-button {
        font-size: 1.25rem;
        padding: 8px 8px 8px 16px
    }
}

@media only screen and (min-width: 1358px) {
    .view-work-button {
        right: 10%;
    }
}

@media only screen and (max-width: 814px) {
    .view-work-button {
        right: 50%;
        top: 75%;
        -webkit-transform: translateX(50%);
                transform: translateX(50%)
    }
}

@media only screen and (max-width: 600px) {
    /*  =================
    Home styles 
    ================= */
    .home-container {
        line-height: 2.3rem;
    }
    #home h1 {
        font-size: 2.5rem;
    }
    #home h3 {
        font-size: 1.2rem;
    }
    .view-work-button {
        top: 70%;
    }
}

@media only screen and (max-width: 341px) {
    .view-work-button {
        padding-left: 5px;
        padding-right: 0;
    }
}

section#about {
	/* background: rgba(0, 0, 0, .3); */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
		url(/static/media/LandingBackground-desktop.36bce882.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}

.about-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.about-heading {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.about-subheading {
	font-size: 1.6rem;
	margin: 1rem 0;
	text-shadow: 1px 1px var(--darkGray);
	width: 80%;
}

.about-info {
	display: flex;
	justify-content: space-evenly;
	padding: 1rem;
	margin-bottom: 40px;
}

.bio {
	width: 46%;
	text-align: left;
}

.bio p,
.sources p {
	font-weight: 300;
}

.sources {
	width: 46%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.button-container {
	position: relative;
	display: block;
	text-align: center;
	border: none;
	background: none;
	cursor: pointer;

	transition: color 0.5s ease-in-out;
}
#popup-button {
	margin-top: 2rem;
}
.button-container:hover,
.button-container:focus {
	color: var(--darkGray);
	outline: none;
}
.button-container:hover .button-bg,
.button-container:focus .button-bg {
	width: 120%;
}

.button-inner {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	/* border-radius: 10px; */
}

.button-bg {
	position: absolute;
	left: -10%;
	background-color: var(--accent);
	width: 0;
	height: 100%;
	z-index: 3;

	-webkit-transform: skewX(-20deg);

	        transform: skewX(-20deg);
	transition: all 0.5s ease-in-out;
}

.button-base {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	left: 0;
	border: 1px solid var(--accent);
	/* border-radius: 10px; */
}

.button-text {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 4;
	transition: all 0.1s;
}

.slick-list {
	overflow: visible !important;
}

.languages {
	position: relative;
	width: 100vw;
}

.language {
	display: flex !important;
	flex-direction: column;
	align-items: center;
}

.fa-html5 {
	color: rgb(253, 126, 20);
}

.fa-css3-alt {
	color: rgb(33, 150, 243);
}

.fa-js {
	color: rgb(239, 216, 29);
}

.fa-git-alt {
	color: rgb(232, 78, 49);
}

.fa-react {
	color: rgb(0, 209, 247);
}

.fa-bootstrap {
	color: rgb(100, 56, 177);
}

.fa-sass {
	color: rgb(208, 100, 155);
}

.overlay-active {
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0px;
	top: 0px;
	opacity: 0.6;
	background-color: rgb(28, 28, 28);
	z-index: 10;
	transition: background-color 0.5s ease-in-out;
}
.content-inactive {
	pointer-events: none;
}
.no-scroll {
	overflow: hidden;
}

.pop-up {
	background-color: var(--darkGray);
	border: 2px solid var(--accent);
	border-radius: 4px;
	width: 60vw;
	height: 95vh;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	z-index: 11;
	box-shadow: 1px 1px 10px 3px black;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 3fr 1fr;
	/* align-items: center; */

	visibility: hidden;
	opacity: 0;
	-webkit-transform: translate(254px, 107px) scale(0.1);
	        transform: translate(254px, 107px) scale(0.1);

	transition: opacity 0.75s, visibility 0.75s, -webkit-transform 0.75s;

	transition: opacity 0.75s, visibility 0.75s, transform 0.75s;

	transition: opacity 0.75s, visibility 0.75s, transform 0.75s, -webkit-transform 0.75s;
}
.pop-up-active {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translate(0px, 0px) scale(1);
	        transform: translate(0px, 0px) scale(1);
}
.pop-up-header {
	grid-column: 1 / span 4;
	grid-row: 1 / span 1;
	justify-items: center;
	align-items: center;
	display: grid;
	justify-items: center;
	font-size: 2vw;
	padding: 30px;
	text-align: center;
}
.pop-up-body {
	grid-column: 1 / span 4;
	grid-row: 2 / span 1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 45% 45% 10%;
	justify-items: center;
	text-align: center;
}
.pop-up-body p {
	padding: 0 30px;
	font-size: 0.9rem;
}
.pop-up-body .bottom {
	grid-column: 1 / span 2;
	display: flex;
	align-items: flex-end;
	font-size: 2vw;
}
.pop-up-links {
	grid-column: 1 / span 4;
	grid-row: 3 / span 1;
	display: flex;
	justify-content: space-evenly;
}
.pop-up-links button {
	height: 50px;
	width: 100px;
	padding: 5px;
	border-radius: 5px;
	color: var(--accent);
	border: 2px solid var(--accent);
	background: transparent;
	margin-top: 20px;
	z-index: 1;
	position: relative;
	overflow: hidden;
	outline: none;

	transition: color 0.5s;
}
.pop-up-links button:hover,
.pop-up-links button:focus {
	cursor: pointer;
	outline: none;
	color: var(--darkGray);
}
.pop-up-links button:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 180%;
	height: 150%;
	background-color: var(--accent);
	z-index: -1;

	transition: -webkit-transform 0.5s ease-in-out;

	transition: transform 0.5s ease-in-out;

	transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
	-webkit-transform: translate(-120%, 0) skew(-30deg);
	        transform: translate(-120%, 0) skew(-30deg);
}
.pop-up-links button:hover::before,
.pop-up-links button:focus::before {
	-webkit-transform: translate(-10%, 0) skew(-15deg);
	        transform: translate(-10%, 0) skew(-15deg);
}

#cancel {
	position: absolute;
	right: 10px;
	top: 10px;
}
#cancel:hover {
	cursor: pointer;
}

@media only screen and (min-width: 1358px) {
	.about-subheading {
		font-size: 2rem;
	}
	.bio p,
	.sources p {
		font-size: 1.25rem;
	}
	.pop-up-body p {
		font-size: 1.15rem;
	}
	.pop-up-links button {
		height: 65px;
		width: 120px;
		font-size: 1.15rem;
	}
}

@media only screen and (max-width: 1000px) {
	.about-heading {
		position: relative;
	}
	.about-subheading {
		font-size: 1.2rem;
	}
	.bio,
	.sources {
		font-size: 0.8rem;
		width: 42%;
	}
	.source-button {
		width: 150px;
		height: 40px;
		line-height: 40px;
		font-size: 1.2rem;
	}
	.pop-up {
		width: 80vw;
		z-index: 110;
	}
	.pop-up-header {
		font-size: 1.25rem;
	}
	.pop-up-body p {
		font-size: 0.85rem;
	}
	.pop-up-body .bottom {
		font-size: 1.25rem;
	}
}

@media only screen and (max-width: 740px) {
	.about-info {
		flex-direction: column;
		align-items: center;
	}
	.bio,
	.source {
		width: 90%;
		text-align: center;
	}
	.about-info {
		margin-bottom: 0;
	}
	.slider {
		margin-top: 30px;
	}
}

@media only screen and (max-width: 600px) {
	/*  =================
    About styles 
    ================= */
	h1 {
		font-size: 3rem;
	}
	.about-container {
		padding: 25px;
	}
	.about-subheading {
		font-size: 0.95rem;
	}
	.bio p,
	.sources p {
		font-size: 0.85rem;
	}
	.bio {
		width: 90%;
	}
	.sources {
		width: 90%;
	}
	.source-button {
		width: 130px;
		height: 33px;
		line-height: 33px;
		font-size: 1rem;
	}
	.pop-up {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 3fr 1fr;
	}
	.pop-up-body {
		grid-column: 1 / span 1;
		grid-row: 2 / span 1;
		grid-template-columns: 1fr;
		grid-template-rows: 0.75fr 1fr 1fr 0.75fr 0.5fr;
		grid-row-gap: 0.5rem;
		row-gap: 0.5rem;
	}
	.pop-up-header {
		font-size: 0.85rem;
		padding: 20px;
		padding-bottom: 0;
	}
	.pop-up-body p {
		font-size: 0.65rem;
		padding: 0 20px;
	}
	.pop-up-body .bottom {
		font-size: 0.85rem;
	}
	.one {
		grid-column: 1 / span 1;
		grid-row: 1 / span 1;
		padding: 0;
	}
	.two {
		grid-column: 1 / span 1;
		grid-row: 2 / span 1;
	}
	.three {
		grid-column: 1 / span 1;
		grid-row: 3 / span 1;
	}
	.four {
		grid-column: 1 / span 1;
		grid-row: 4 / span 1;
	}
	.bottom {
		grid-column: 1 / span 1;
		grid-row: 5 / span 1;
	}
	.pop-up-links {
		grid-column: 1 / span 1;
		grid-row: 3 / span 1;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		justify-items: center;
		align-items: center;
		padding: 0 0 10px 0;
	}
	.pop-up-links button {
		height: 30px;
		width: 100px;
		padding: 2px;
		font-size: 0.8rem;
	}
}

#project-section-container {
	height: auto;
	align-items: center;
	max-width: none;
}

#projects {
	z-index: 30;
	display: flex;
	/* background-image: linear-gradient(
		to right,
		hsl(192deg 100% 13%),
		hsl(165deg 83% 12%)
	); */
	background-image: none;
	background-color: gray;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.575), 0 8px 16px rgba(0, 0, 0, 0.575);
	padding: 0;
	/* height: 110vh; */
}

.section-container {
	width: 100%;
	max-width: 1200px;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.project-flex {
	height: 100%;
}

#projects h1 {
	z-index: 2;
	color: var(--accent);
	text-shadow: 1px 1px var(--darkGray);
	width: 100%;
	position: relative;
	top: 10px;
	margin-bottom: 40px;
}

.project-container {
	display: block;
	width: 100%;
	height: 100%;
}

.project-row {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	min-height: 400px;
	background-color: white;
}

.project-display {
	min-width: 300px;
	min-height: 400px;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	flex: 1 1;
	position: relative;
}

.project-display:hover .project-over {
	opacity: 0.6;
}

.project-display:hover .project-links {
	opacity: 1;
	visibility: visible;
}

.project-over {
	width: 100%;
	height: 100%;
	min-height: 400px;
	opacity: 0.5;
	background-color: var(--accent);
	transition: opacity 0.1s ease-in-out;
}

.project-description {
	background: var(--darkGray);
	min-width: 300px;
	height: 100%;
	min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 1;
}

.project-title {
	font-size: 2rem;
	font-weight: 600;
	color: var(--accent);
	padding: 0 30px;
}

.project-info {
	font-size: 1.2rem;
	max-width: 60ch;
	margin: 20px 0;
	padding: 0 74px;
}

.project-links {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	transition: opacity 0.1s ease-in-out;
	opacity: 0;
	visibility: hidden;
}

.project-links a {
	font-size: 1.2rem;
	background-color: var(--darkGray);
	padding: 13px;
	border-radius: 5px;
	opacity: 0.9;
	transition: all 0.1s ease-in-out;
}
.project-links a:hover {
	color: var(--accent);
	opacity: 1;
}

@media only screen and (max-width: 1032px) {
	.project-title {
		font-size: 1.8rem;
	}
	.project-info {
		font-size: 1rem;
	}
}

@media only screen and (max-width: 1000px) {
	.project-info {
		padding: 0 30px;
	}
}

@media only screen and (max-width: 800px) {
	.reverse {
		flex-direction: column-reverse;
	}

	.project-title {
		font-size: 2.25rem;
	}
	.project-info {
		font-size: 1.2rem;
	}
}

@media only screen and (max-width: 494px) {
	.project-title {
		font-size: 2rem;
	}
	.project-info {
		font-size: 1rem;
	}
}

.popup {
    position: fixed;
    left: 0; 
    right: 0; 
    bottom: 0; 
    top: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 580px;
    width: 60vw;
    max-width: 900px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 100vh;
    /* height: 95vh; */
    background-color: var(--darkGray);
    border: 2px solid var(--accent);
    border-radius: 4px;
    box-shadow: 1px 1px 10px 3px black;
    z-index: 110;
    overflow-y: scroll;

    visibility: hidden;
    opacity: 0;

    transition: opacity .5s, visibility .5s, -webkit-transform .5s;

    transition: opacity .5s, visibility .5s, transform .5s;

    transition: opacity .5s, visibility .5s, transform .5s, -webkit-transform .5s;
}
.popup::-webkit-scrollbar {
    display: none;
}
.popup {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}
.popup-active {
    opacity: 1;
    visibility: visible;
}

.popup-header {
    font-size: 1.4rem;
    padding: 40px;
    text-align: center;
}

.popup-body {
    display: flex;
    flex-wrap: wrap;
}

.popup-item {
    width: 50%;
    padding: 10px 40px;
}

.popup-bottom {
    padding: 20px
}

.popup-links {
    display: flex;
    flex-wrap: wrap;
    /* flex-basis: 50%; */
    justify-content: space-evenly;
    margin-bottom: 30px;
}

.popup-links button {
    margin-top: 20px;
}
@media only screen and (max-width: 600px) {
    .popup {
        min-width: 100vw;
        width: 100vw;
    }

    .popup-item {
        width: 100%;
    }
}
#contact{
    min-height: 101vh;
    display: flex;
    position: relative;
}
    
.copy {
    color: #878787;
    position: absolute;
    bottom: 10px;
    font-size: 14px;
    left: 0; 
    right: 0;
    margin: auto;
}

.contact-form-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    justify-content: space-evenly;
}

.contact-mesage {
    max-width: 40ch;
    margin-bottom: 40px !important;
}

@media only screen and (min-width: 1358px) {
    .contact-mesage {
        font-size: 1.25rem;
    }
}
 
@media only screen and (max-width: 600px) {
    .contact-form-container {
        margin-bottom: 30px;
    }
}
.contact-form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.input-container {
	position: relative;
	width: 100%;
}

.input {
	margin-bottom: 10px;
	border: none;
	padding: 13px 7px 0;
	background-color: var(--lightGray);
	color: white;
}
.input:focus {
	border: none;
	outline: none;
}
.input:focus ~ .floating-label,
.input:not(:focus):valid ~ .floating-label {
	left: 7px;
	top: 1px;
	font-size: 11px;
	color: var(--accent);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px var(--lightGray) inset !important;
	-webkit-text-fill-color: white !important;
}

.floating-label {
	position: absolute;
	pointer-events: none;
	left: 7px;
	top: 13px;
	transition: 0.2s ease all;
	color: gray;
}

.contact-form input {
	height: 50px;
	max-width: 350px;
	width: 100%;
}

.contact-form textarea {
	width: 100%;
	max-width: 350px;
	resize: none;
	padding: 22px 7px;
}

.form-button {
	width: 110px;
	height: 50px;
	line-height: 50px;
	letter-spacing: 1px;
	font-size: 1rem;
	color: var(--accent);
	border-radius: none;

	transition: all 0.1s;
}

@media only screen and (max-width: 756px) {
	.contact-container {
		display: flex;
		flex-direction: column;
	}
	.contact-message {
		margin-bottom: 30px;
	}
	.contact-form input,
	.contact-form textarea {
		/* min-width: 250px;
        width: 70vw;
        max-width: 350px; */
	}
}

@-webkit-keyframes logo-load {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1;
    }
}

@keyframes logo-load {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes nav-load {
    0% {
        -webkit-transform: translateX(-110%);
                transform: translateX(-110%);
    }

    100% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@keyframes nav-load {
    0% {
        -webkit-transform: translateX(-110%);
                transform: translateX(-110%);
    }

    100% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}    
@-webkit-keyframes nav-tablet-load {
    0% {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}    
@keyframes nav-tablet-load {
    0% {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes btn-load {
    0% {
        -webkit-transform: translateX(150%);
                transform: translateX(150%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes btn-load {
    0% {
        -webkit-transform: translateX(150%);
                transform: translateX(150%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes icon-load {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }

    90% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes icon-load {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }

    90% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes flash {
    50% {
        opacity: 0;
    }
}

@keyframes flash {
    50% {
        opacity: 0;
    }
}

@-webkit-keyframes left{
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(-35%);
                transform: translateX(-35%);
    }
    80% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes left{
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(-35%);
                transform: translateX(-35%);
    }
    80% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
} 

@-webkit-keyframes middle {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    4% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    20% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    40% {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
    60% {
        -webkit-transform: rotate(1080deg);
                transform: rotate(1080deg);
    }
    78% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
} 

@keyframes middle {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    4% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    20% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    40% {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
    60% {
        -webkit-transform: rotate(1080deg);
                transform: rotate(1080deg);
    }
    78% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
}

@-webkit-keyframes right{
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(35%);
                transform: translateX(35%);
    }
    80% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes right{
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    60% {
        -webkit-transform: translateX(35%);
                transform: translateX(35%);
    }
    80% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes down-arrow {
    0% {
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px)
    }
    50% {
        -webkit-transform: translateY(3px);
                transform: translateY(3px)
    }
    100% {
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px)
    }
}

@keyframes down-arrow {
    0% {
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px)
    }
    50% {
        -webkit-transform: translateY(3px);
                transform: translateY(3px)
    }
    100% {
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px)
    }
}

.booth {
	background-image: linear-gradient(
		to bottom right,
		rgb(49, 49, 49),
		rgb(20, 20, 20)
	);
	text-align: center;
}

.booth h1,
.booth p {
	margin: 40px auto;
	max-width: 40ch;
}
.booth-pics-container {
	width: 90%;
	margin-left: 5%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.booth-pics-container img {
	margin: 20px;
	width: 100%;
}

#booth-button {
	left: 0;
	right: 0;
	margin: 40px auto;
}



.btn-ctr {
    position: relative;
    display: block;
    text-align: center;
    border: none;
    background: none;
    cursor: pointer;
    transition: color .5s ease-in-out;
    letter-spacing: 1px;
    color: var(--accent);
    /* width: 100%;
    height: 100%; */
}

.btn-ctr:hover, 
.btn-ctr:focus {
    color: var(--darkGray);
    outline: none;
}
.btn-ctr:hover .btn-bg,
.btn-ctr:focus .btn-bg {
    width: 120%;
}


.btn-inner {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.btn-bg {
    position: absolute;
    left: -10%;
    background-color: var(--accent);
    width: 0;
    height: 100%;
    z-index: 3;
    -webkit-transform: skewX(-20deg);
            transform: skewX(-20deg);
    transition: all .5s ease-in-out;
}

.btn-base {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    border: 1px solid var(--accent);
}

.btn-text {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 4;
    transition: all .1s;
}

:root {
	--accent: rgb(4, 255, 201);
	--secondaryAccent: rgb(7, 205, 255);
	--darkGray: rgb(22, 22, 22);
	--lightGray: rgb(58, 58, 58);
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	color: white;
	overflow-x: hidden;
}

section {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 100%;
	background-image: linear-gradient(
		to bottom right,
		rgb(49, 49, 49),
		rgb(20, 20, 20)
	);
}

a {
	text-decoration: none;
	color: inherit;
}

h1 {
	font-size: 3.25rem;
	text-shadow: 1.5px 1px black;
	color: rgb(4, 255, 201);
	color: var(--accent);
}

.section {
	padding: 70px;
}

.container {
	width: 100%;
	height: 100%;
	position: relative;
}

.m-10 {
	margin: 10px;
}

.my-3 {
	margin: 3px 0;
}

.display {
	display: none;
}

.visible {
	opacity: 1;
	visibility: visible;
}

.invisible {
	opacity: 0;
	visibility: hidden;
}

@media screen and (max-width: 600px) {
	.section {
		padding: 70px 20px 20px;
	}
}

