@import './loader.css';
@import './audio.css';
@import './thanks.css';
@import './landing.css';
@import './dev.css';
@import './button.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,500;1,400;1,500&display=swap');

:root {
	--accent: rgb(4, 255, 201);
	--secondaryAccent: rgb(7, 205, 255);
	--darkGray: rgb(22, 22, 22);
	--lightGray: rgb(58, 58, 58);
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	color: white;
	overflow-x: hidden;
}

section {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 100%;
	background-image: linear-gradient(
		to bottom right,
		rgb(49, 49, 49),
		rgb(20, 20, 20)
	);
}

a {
	text-decoration: none;
	color: inherit;
}

h1 {
	font-size: 3.25rem;
	text-shadow: 1.5px 1px black;
	color: var(--accent);
}

.section {
	padding: 70px;
}

.container {
	width: 100%;
	height: 100%;
	position: relative;
}

.m-10 {
	margin: 10px;
}

.my-3 {
	margin: 3px 0;
}

.display {
	display: none;
}

.visible {
	opacity: 1;
	visibility: visible;
}

.invisible {
	opacity: 0;
	visibility: hidden;
}

@media screen and (max-width: 600px) {
	.section {
		padding: 70px 20px 20px;
	}
}
