#audio {
    display: none;
}

.sound-icon {
    z-index: 100;
    cursor: pointer;
    position: fixed;
    right: 20px; 
    top: 20px;
}

@media only screen and (max-width: 1000px) {
    .sound-icon-dev {
        top: 88px;
    }
}

@media only screen and (max-width: 600px) {
    .sound-icon-dev {
        top: 14px;
        right: 72px;
    }
}