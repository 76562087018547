#project-section-container {
	height: auto;
	align-items: center;
	max-width: none;
}

#projects {
	z-index: 30;
	display: flex;
	/* background-image: linear-gradient(
		to right,
		hsl(192deg 100% 13%),
		hsl(165deg 83% 12%)
	); */
	background-image: none;
	background-color: gray;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.575), 0 8px 16px rgba(0, 0, 0, 0.575);
	padding: 0;
	/* height: 110vh; */
}

.section-container {
	width: 100%;
	max-width: 1200px;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.project-flex {
	height: 100%;
}

#projects h1 {
	z-index: 2;
	color: var(--accent);
	text-shadow: 1px 1px var(--darkGray);
	width: 100%;
	position: relative;
	top: 10px;
	margin-bottom: 40px;
}

.project-container {
	display: block;
	width: 100%;
	height: 100%;
}

.project-row {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	min-height: 400px;
	background-color: white;
}

.project-display {
	min-width: 300px;
	min-height: 400px;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	flex: 1 1 0px;
	position: relative;
}

.project-display:hover .project-over {
	opacity: 0.6;
}

.project-display:hover .project-links {
	opacity: 1;
	visibility: visible;
}

.project-over {
	width: 100%;
	height: 100%;
	min-height: 400px;
	opacity: 0.5;
	background-color: var(--accent);
	transition: opacity 0.1s ease-in-out;
}

.project-description {
	background: var(--darkGray);
	min-width: 300px;
	height: 100%;
	min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 1 0px;
}

.project-title {
	font-size: 2rem;
	font-weight: 600;
	color: var(--accent);
	padding: 0 30px;
}

.project-info {
	font-size: 1.2rem;
	max-width: 60ch;
	margin: 20px 0;
	padding: 0 74px;
}

.project-links {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	transition: opacity 0.1s ease-in-out;
	opacity: 0;
	visibility: hidden;
}

.project-links a {
	font-size: 1.2rem;
	background-color: var(--darkGray);
	padding: 13px;
	border-radius: 5px;
	opacity: 0.9;
	transition: all 0.1s ease-in-out;
}
.project-links a:hover {
	color: var(--accent);
	opacity: 1;
}

@media only screen and (max-width: 1032px) {
	.project-title {
		font-size: 1.8rem;
	}
	.project-info {
		font-size: 1rem;
	}
}

@media only screen and (max-width: 1000px) {
	.project-info {
		padding: 0 30px;
	}
}

@media only screen and (max-width: 800px) {
	.reverse {
		flex-direction: column-reverse;
	}

	.project-title {
		font-size: 2.25rem;
	}
	.project-info {
		font-size: 1.2rem;
	}
}

@media only screen and (max-width: 494px) {
	.project-title {
		font-size: 2rem;
	}
	.project-info {
		font-size: 1rem;
	}
}
