@keyframes logo-load {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1;
    }
}

@keyframes nav-load {
    0% {
        transform: translateX(-110%);
    }

    100% {
        transform: translateX(0%);
    }
}    
@keyframes nav-tablet-load {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes btn-load {
    0% {
        transform: translateX(150%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes icon-load {
    0% {
        transform: scale(0);
    }

    90% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes flash {
    50% {
        opacity: 0;
    }
}

@keyframes left{
    0% {
        transform: translateX(0);
    }
    60% {
        transform: translateX(-35%);
    }
    80% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
} 

@keyframes middle {
    0% {
        transform: rotate(0);
    }
    4% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(360deg);
    }
    40% {
        transform: rotate(720deg);
    }
    60% {
        transform: rotate(1080deg);
    }
    78% {
        transform: rotate(0);
    }
}

@keyframes right{
    0% {
        transform: translateX(0);
    }
    60% {
        transform: translateX(35%);
    }
    80% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes down-arrow {
    0% {
        transform: translateY(-3px)
    }
    50% {
        transform: translateY(3px)
    }
    100% {
        transform: translateY(-3px)
    }
}
